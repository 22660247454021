.scroll-to-top {
  position: fixed;
  bottom: 90px;
  right: 40px;
  width: 40px;
  height: 40px;
  background-color: #333;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
  border: solid 1px;
  box-shadow: 2px 3px 10px 0 #ccc;
}

.scroll-to-top.visible {
  opacity: 1;
}
