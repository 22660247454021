@charset "UTF-8";

.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0;
}

button.rbc-btn {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled].rbc-btn {
  cursor: not-allowed;
}

button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit;
}

.rbc-abs-full,
.rbc-row-bg {
  overflow: visible;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.rbc-ellipsis,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
  display: block;
  overflow: visible;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rbc-rtl {
  direction: rtl;
}

.rbc-off-range {
  color: #9d9d9d;
}

.rbc-off-range-bg {
  background: #eaeaea;
}

.rbc-header {
  display: flex;
  height: 40px;
  overflow: visible;
  flex: 1 0 0%;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  color: #353a54;
  font-weight: 500;
  font-size: 90%;
  min-height: 0;
  padding: 15px 0;
  /* background: #f7f7f7; */
  border-bottom: 1px solid #ddd;
  align-items: center;
  justify-content: center;
  border-top: none;
}

.rbc-header+.rbc-header {
  border-left: 1px solid #ddd;
}

.rbc-rtl .rbc-header+.rbc-header {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}

.rbc-header>a,
.rbc-header>a:active,
.rbc-header>a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-row-content {
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  height: 100%;
  z-index: 4;
}

.rbc-today {
  /* border: 2px solid #2671D1;
  border-left: 2px solid #2671D1 !important; */
  background-color: rgb(106, 168, 248) !important;
}

.active-rbc-cell {
  border: 2px solid #2671d1;
  border-left: 2px solid #2671d1 !important;
}

/* 
.rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
} */

.rbc-toolbar .rbc-toolbar-label {
  flex-grow: 1;
  padding: 0 10px;
  text-align: center;
}

.rbc-toolbar button {
  color: #373a3c;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 1px solid #ccc;
  padding: 0.375rem 1rem;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background-color: #e6e6e6;
  border-color: #adadad;
}

.rbc-toolbar button:active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button.rbc-active:focus {
  color: #373a3c;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}

.rbc-toolbar button:focus {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.rbc-toolbar button:hover {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.rbc-btn-group {
  display: inline-block;
  white-space: nowrap;
}

.rbc-btn-group>button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-btn-group>button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rbc-rtl .rbc-btn-group>button:first-child:not(:last-child) {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rbc-rtl .rbc-btn-group>button:last-child:not(:first-child) {
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-btn-group>button:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.rbc-btn-group button+button {
  margin-left: -1px;
}

.rbc-rtl .rbc-btn-group button+button {
  margin-left: 0;
  margin-right: -1px;
}

.rbc-btn-group+.rbc-btn-group,
.rbc-btn-group+button {
  margin-left: 10px;
}

.rbc-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  background-color: #3174ad;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left;
}


.rbc-slot-selecting .rbc-event {
  cursor: inherit;
  pointer-events: none;
}

.rbc-event.rbc-selected {
  background-color: #265985;
}

.rbc-event:focus {
  outline: 5px auto #3b99fc;
}

.rbc-event-label {
  font-size: 80%;
}

.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}

.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-row {
  display: flex;
  flex-direction: row;
}

.rbc-row-segment {
  padding: 0 1px 1px 1px;
}

.rbc-selected-cell {
  background-color: rgba(0, 0, 0, 0.1);
}

.rbc-show-more {
  display: none;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal;
}

.rbc-month-view {
  position: relative;
  border-bottom: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  height: 100%;
}

.rbc-month-header {
  display: flex;
  flex-direction: row;
}

.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0 0;
  flex-basis: 0px;
  overflow: visible;
  height: 100%;
}

.rbc-month-row+.rbc-month-row {
  border-top: 1px solid #ddd;
  /* border: none !important */
}

.rbc-date-cell {
  flex: 1 1 0;
  min-width: 0;
  /* color: #353a54; */
  color: transparent;
  font-family: Poppins;
  font-weight: 300;
  margin-top: 7px;
  text-align: center;
  /* cursor: pointer; */
}

.rbc-date-cell.rbc-off-range {
  /* color: #9d9d9d !important; */
  color: transparent;
}

/* .rbc-date-cell.rbc-now {
    font-weight: bold; } */
.rbc-date-cell>a,
.rbc-date-cell>a:active,
.rbc-date-cell>a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-row-bg {
  display: flex;
  flex-direction: row;
  flex: 1 0 0;
  overflow: visible;
}

.event::after {
  /* content: '1'; */
  background-color: red;
  color: white;
  position: absolute;
  width: 20px;
  height: 20px;
  right: -5px;
  top: -5px;
  border-radius: 20px;
  z-index: 10;
  text-align: center;
  vertical-align: middle;
  font-family: Poppins;
  font-size: 13px;
}

.event::before {
  background-color: #adadad;
  color: white;
  position: absolute;
  width: 20px;
  height: 20px;
  left: -5px;
  top: -5px;
  border-radius: 20px;
  z-index: 10;
  text-align: center;
  vertical-align: middle;
  font-family: Poppins;
  font-size: 13px;
}

.qb1.event::before {
  content: '1';
}

.qb2.event::before {
  content: '2';
}

.qb3.event::before {
  content: '3';
}

.qb4.event::before {
  content: '4';
}

.qb5.event::before {
  content: '5';
}

.qb6.event::before {
  content: '6';
}

.qb7.event::before {
  content: '7';
}

.qb8.event::before {
  content: '8';
}

.qb9.event::before {
  content: '9';
}

.qb10.event:before {
  content: '10';
}

.qb11.event::before {
  content: '11';
}

.qb12.event::before {
  content: '12';
}

.qb13.event::before {
  content: '13';
}

.qb14.event::before {
  content: '14';
}

.qb15.event::before {
  content: '15';
}

.qb16.event::before {
  content: '16';
}

.qb17.event::before {
  content: '17';
}

.qb18.event::before {
  content: '18';
}

.qb19.event::before {
  content: '19';
}

.qb20.event::before {
  content: '20';
}

.qb21.event::before {
  content: '21';
}

.q1.event::after {
  content: '1';
}

.q2.event::after {
  content: '2';
}

.q3.event::after {
  content: '3';
}

.q4.event::after {
  content: '4';
}

.q5.event::after {
  content: '5';
}

.q6.event::after {
  content: '6';
}

.q7.event::after {
  content: '7';
}

.q8.event::after {
  content: '8';
}

.q9.event::after {
  content: '9';
}

.q10.event::after {
  content: '10';
}

.q11.event::after {
  content: '11';
}

.q12.event::after {
  content: '12';
}

.q13.event::after {
  content: '13';
}

.q14.event::after {
  content: '14';
}

.q15.event::after {
  content: '15';
}

.q16.event::after {
  content: '16';
}

.q17.event::after {
  content: '17';
}

.q18.event::after {
  content: '18';
}

.q19.event::after {
  content: '19';
}

.q20.event::after {
  content: '20';
}

.q21.event::after {
  content: '21';
}

.rbc-day-bg {
  position: relative;
  overflow: visible;
  flex: 1 0 0%;
}

.rbc-day-bg.event {
  background: #90cce7;
}

.rbc-day-bg.event.rbc-off-range-bg {
  background: #ddf1f9;
}

.rbc-day-bg+.rbc-day-bg {
  border-left: 1px solid #ddd;
}

.rbc-rtl .rbc-day-bg+.rbc-day-bg {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}

.rbc-overlay {
  position: absolute;
  z-index: 5;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px;
}

.rbc-overlay>*+* {
  margin-top: 1px;
}

.rbc-overlay-header {
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px -10px;
  padding: 2px 10px;
}

.rbc-agenda-view {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow: auto;
}

.rbc-agenda-view table.rbc-agenda-table {
  width: 100%;
  /* border: 1px solid #ddd; */
  border: none !important;
  border-spacing: 0;
  border-collapse: collapse;
}

.rbc-agenda-view table.rbc-agenda-table tbody>tr>td {
  padding: 5px 10px;
  vertical-align: top;
}

.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  display: none;
  padding-left: 15px;
  padding-right: 15px;
  text-transform: lowercase;
}

.rbc-agenda-view table.rbc-agenda-table tbody>tr>td+td {
  border-left: 1px solid red;
}

.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody>tr>td+td {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}

.rbc-agenda-view table.rbc-agenda-table tbody>tr+tr {
  border-top: none !important;
}

.rbc-agenda-view table.rbc-agenda-table thead>tr>th {
  padding: 3px 5px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead>tr>th {
  text-align: right;
}

.rbc-agenda-time-cell {
  text-transform: lowercase;
}

.rbc-agenda-time-cell .rbc-continues-after:after {
  content: ' »';
}

.rbc-agenda-time-cell .rbc-continues-prior:before {
  content: '« ';
}

.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
  width: 0%;
  white-space: nowrap;
}

.rbc-agenda-event-cell {
  width: 100%;
}

.rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.rbc-time-column .rbc-timeslot-group {
  flex: 1;
}

.rbc-timeslot-group {
  border-bottom: 1px solid #ddd;
  min-height: 40px;
  display: flex;
  flex-flow: column nowrap;
}

.rbc-time-gutter,
.rbc-header-gutter {
  flex: none;
}

.rbc-label {
  padding: 0 5px;
}

.rbc-day-slot {
  position: relative;
}

.rbc-day-slot .rbc-events-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  margin-right: 10px;
  top: 0;
}

.rbc-day-slot .rbc-events-container.rbc-rtl {
  left: 10px;
  right: 0;
}

.rbc-day-slot .rbc-event {
  border: 1px solid #265985;
  display: flex;
  max-height: 100%;
  min-height: 20px;
  flex-flow: column wrap;
  align-items: flex-start;
  overflow: visible;
  position: absolute;
}

.rbc-day-slot .rbc-event-label {
  flex: none;
  padding-right: 5px;
  width: auto;
}

.rbc-day-slot .rbc-event-content {
  width: 100%;
  flex: 1 1 0;
  word-wrap: break-word;
  line-height: 1;
  height: 100%;
  min-height: 1em;
}

.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #f7f7f7;
}

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
  position: sticky;
  left: 0;
  background-color: white;
  border-right: 1px solid #ddd;
  z-index: 10;
  margin-right: -1px;
}

.rbc-time-view-resources .rbc-time-header {
  overflow: visible;
}

.rbc-time-view-resources .rbc-time-header-content {
  min-width: auto;
  flex: 1 0 0;
  flex-basis: 0px;
}

.rbc-time-view-resources .rbc-time-header-cell-single-day {
  display: none;
}

.rbc-time-view-resources .rbc-day-slot {
  min-width: 140px;
}

.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
  width: 140px;
  flex: 1 1 0;
  flex-basis: 0 px;
}

.rbc-time-header-content+.rbc-time-header-content {
  margin-left: -1px;
}

.rbc-time-slot {
  flex: 1 0 0;
}

.rbc-time-slot.rbc-now {
  font-weight: bold;
}

.rbc-day-header {
  text-align: center;
}

.rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 75%;
  width: 100%;
  padding: 3px;
}

.rbc-slot-selecting {
  cursor: move;
}

.rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  border: 1px solid #ddd;
  min-height: 0;
}

.rbc-time-view .rbc-time-gutter {
  white-space: nowrap;
}

.rbc-time-view .rbc-allday-cell {
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  position: relative;
}

.rbc-time-view .rbc-allday-cell+.rbc-allday-cell {
  border-left: 1px solid #ddd;
}

.rbc-time-view .rbc-allday-events {
  position: relative;
  z-index: 4;
}

.rbc-time-view .rbc-row {
  box-sizing: border-box;
  min-height: 20px;
}

.rbc-time-header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
}

.rbc-time-header.rbc-overflowing {
  border-right: 1px solid #ddd;
}

.rbc-rtl .rbc-time-header.rbc-overflowing {
  border-right-width: 0;
  border-left: 1px solid #ddd;
}

.rbc-time-header>.rbc-row:first-child {
  border-bottom: 1px solid #ddd;
}

.rbc-time-header>.rbc-row.rbc-row-resource {
  border-bottom: 1px solid #ddd;
}

.rbc-time-header-cell-single-day {
  display: none;
}

.rbc-time-header-content {
  flex: 1;
  display: flex;
  min-width: 0;
  flex-direction: column;
  border-left: 1px solid #ddd;
}

.rbc-rtl .rbc-time-header-content {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}

.rbc-time-header-content>.rbc-row.rbc-row-resource {
  border-bottom: 1px solid #ddd;
  flex-shrink: 0;
}

.rbc-time-content {
  display: flex;
  flex: 1 0 0%;
  align-items: flex-start;
  width: 100%;
  border-top: 2px solid #ddd;
  overflow-y: auto;
  position: relative;
}

.rbc-time-content>.rbc-time-gutter {
  flex: none;
}

.rbc-time-content>*+*>* {
  border-left: 1px solid #ddd;
}

.rbc-rtl .rbc-time-content>*+*>* {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}

.rbc-time-content>.rbc-day-slot {
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
}

.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #74ad31;
  pointer-events: none;
}